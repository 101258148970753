<template>
    <div class="step-head flex flex-x" :class="{ too: noTongue||noFace }">
        <div class="step-ni active" v-if="!noFace">
            <div class="step-flex">
                <div class="step-solid"></div>
                <p>面诊</p>
            </div>
        </div>
        <div class="flex flex-y flex-1" v-if="!noFace&&!noTongue">
          <div class="step-line"></div>
        </div>
        <div class="step-ni" :class="{ active: active==2||active==3 }" v-if="!noTongue">
            <div class="step-flex">
                <div class="step-solid"></div>
                <p>舌诊</p>
            </div>
        </div>
        <div class="flex flex-y flex-1" v-if="!noAsk">
          <div class="step-line"></div>
        </div>
        <div class="step-ni" :class="{ active: active==3 }" v-if="!noAsk">
            <div class="step-flex">
                <div class="step-solid"></div>
                <p>问诊</p>
            </div>
        </div>
      </div>
</template>
<script>

export default {
  name: 'face',
  data(){
      return{
          noFace:false,         //隐藏面诊
          noTongue:false,       //隐藏舌诊
          noAsk:false           //隐藏问诊
      }
  },
  props: ["active"],
  mounted(){
        let flow = sessionStorage.getItem('flow');
        let flow_step = "";
        if(flow){
            flow_step = JSON.parse(flow).flow_info.flow_step;
        }
        switch(flow_step) {
            case 1:     //面
                this.noTongue=true;
                break;
            case 4:     //舌+健康问诊
                this.noFace=true;
                break;
            case 6:     //面+健康问诊
                this.noTongue=true;
                break;
            case 13:    //舌+问
                this.noFace=true;
                break;
            case 14:    //舌
                this.noFace=true;
                break;
        }   //其他不变
        
  },
}
</script>

<style lang="scss" scoped> 
    @import './face'; 
</style>